<template>
  <v-row justify="center">
    <v-dialog v-model="show" :persistent="false" :light="true" :fullscreen="true" :scrollable="false" overlay-color="tertiary" max-width="80%" class="pa-2 elevation-0" content-class="elevation-0">
      <base-material-card class="mt-6">
        <template #heading>
          <div class="display-2 font-weight-light text-uppercase">
            {{ merchandiseItem.id === undefined ? $t('purchase.merchandise.items.form.edit.create.title') : $t('purchase.merchandise.items.form.edit.update.title') }}
            {{ $t('purchase.merchandise.items.type.' + String(merchandiseItem.type)) }}
          </div>
        </template>

        <v-form ref="formMerchandiseItem" v-model="valid" lazy-validation @keyup.native.enter="validate() && submitMerchandiseItem()">
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete v-model="merchandiseId" :items="merchandises" item-value="id" item-text="name" chips dense :rules="merchandiseRules"
                                :label="$t('purchase.merchandise.items.form.edit.input.merchandise.label')" :disabled="loading" :loading="loading" :clearable="true"
                                @change="updatePackagingPrices(type === 'purchase' ? 'excluded' : (type === 'check' ? 'included' : undefined)),
                                         updateUnitPrices(type === 'purchase' ? 'excluded' : (type === 'check' ? 'included' : undefined)),
                                         updateDefaultPackaging()"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete v-model="supplierId" :items="suppliers.filter((supplier) => supplier.type === type)" item-value="id" item-text="name" chips dense :rules="supplierRules"
                                :label="$t('purchase.merchandise.items.form.edit.input.supplier.label')" :disabled="loading" :loading="loading" :clearable="true"
                />
              </v-col>
            </v-row>
            <fieldset v-if="merchandiseId" class="pa-2">
              <legend>{{ $t('purchase.merchandise.items.form.edit.group.packaging') }}</legend>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="packagingCapacity" type="number" :min="packagingCapacityMin" :max="packagingCapacityMax" dense class="d-inline-block w100" :rules="packagingCapacityRules"
                                :label="$t('purchase.merchandise.items.form.edit.input.packagingCapacity.label')"
                                :disabled="loading" :loading="loading" :clearable="true"
                                @keyup="updateConversionPrice('packaging'),updateUnitPrices('excluded')"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select v-model="packagingUnit" :items="packagingUnits" item-value="id" item-text="name" chips dense :rules="packagingUnitRules"
                            :label="$t('purchase.merchandise.items.form.edit.input.packagingUnit.label')"
                            :disabled="loading" :loading="loading" :clearable="true"
                            @change="updatePackagingCapacity(),updateConversionPrice('packaging'),updateUnitPrices('excluded')"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="quantity" type="number" :min="1" :max="10000" dense class="d-inline-block w100" :rules="quantityRules"
                                :label="$t('purchase.merchandise.items.form.edit.input.quantity.label')" :disabled="type === 'check' || loading" :loading="loading" :clearable="true"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="packagingPriceTaxIncluded" type="number" :min="0" :max="1000" dense class="d-inline-block w100" :rules="packagingPriceTaxIncludedRules"
                                :label="$t('purchase.merchandise.items.form.edit.input.packagingPriceTaxIncluded.label')" :disabled="type === 'purchase' || loading" :loading="loading" :clearable="true"
                                @keyup="updatePackagingPrices('included'),updateConversionPrice('packaging'),updateUnitPrices('excluded')"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="packagingPriceTaxExcluded" type="number" :min="0" :max="1000" dense class="d-inline-block w100" :rules="packagingPriceTaxExcludedRules"
                                :label="$t('purchase.merchandise.items.form.edit.input.packagingPriceTaxExcluded.label')" :disabled="type === 'check' || loading" :loading="loading" :clearable="true"
                                @keyup="updatePackagingPrices('excluded'),updateConversionPrice('packaging'),updateUnitPrices('excluded')"
                  />
                </v-col>
              </v-row>
            </fieldset>
            <fieldset v-if="merchandiseId" class="pa-2">
              <legend>{{ $t('purchase.merchandise.items.form.edit.group.unit') }}</legend>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="unitPriceTaxIncluded" type="number" :min="0" :max="1000" dense style="width:60%" class="d-inline-block" :rules="unitPriceTaxIncludedRules"
                                :label="$t('purchase.merchandise.items.form.edit.input.unitPriceTaxIncluded.label')" :disabled="type === 'purchase' || loading" :loading="loading" :clearable="true"
                                @keyup="updateUnitPrices('included'),updateConversionPrice('unit'),updatePackagingPrices('excluded')"
                  />
                  <span>{{ $t('common.price.currency.EUR.symbol') }} {{ $t('common.price.taxIncluded.symbol') }} par {{ merchandiseUnit }}</span>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="unitPriceTaxExcluded" type="number" :min="0" :max="1000" dense style="width:60%" class="d-inline-block" :rules="unitPriceTaxExcludedRules"
                                :label="$t('purchase.merchandise.items.form.edit.input.unitPriceTaxExcluded.label')" :disabled="type === 'check' || loading" :loading="loading" :clearable="true"
                                @keyup="updateUnitPrices('excluded'),updateConversionPrice('unit'),updatePackagingPrices('excluded')"
                  />
                  <span>{{ $t('common.price.currency.EUR.symbol') }} {{ $t('common.price.taxExcluded.symbol') }} par {{ merchandiseUnit }}</span>
                </v-col>
              </v-row>
            </fieldset>
            <v-row v-if="merchandiseId" dense class="pt-2">
              <v-col cols="12" sm="6" md="3">
                <v-autocomplete v-model="origin" :items="origins" chips dense
                                :label="$t('purchase.merchandise.items.form.edit.input.origin.label')" :disabled="loading" :loading="loading" :clearable="true"
                />
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-select v-model="category" :items="['EXTRA', 'I', 'II']" chips dense
                          :label="$t('purchase.merchandise.items.form.edit.input.category.label')" :disabled="loading" :loading="loading" :clearable="true"
                />
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field v-model="variety" type="text" :counter="varietyMaxLength" dense :rules="varietyRules"
                              :label="$t('purchase.merchandise.items.form.edit.input.variety.label')" :disabled="loading" :loading="loading" :clearable="true"
                />
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field v-model="size" type="text" :counter="sizeMaxLength" dense :rules="sizeRules"
                              :label="$t('purchase.merchandise.items.form.edit.input.size.label')" :disabled="loading" :loading="loading" :clearable="true"
                />
              </v-col>
            </v-row>
            <v-row v-if="merchandiseId" dense>
              <v-col v-if="type === 'purchase'" cols="12" sm="6" md="4">
                <v-dialog ref="dialog" v-model="expiresAtSelector" persistent width="290px">
                  <template #activator="{ on, attrs }">
                    <v-text-field v-model="expiresAtFormatted" :label="$t('purchase.merchandise.items.form.edit.input.expiresAt.label')" :disabled="loading" :loading="loading"
                                  prepend-icon="mdi-calendar" readonly v-bind="attrs" :clearable="true" v-on="on"
                    />
                  </template>
                  <v-date-picker v-model="expiresAt" no-title first-day-of-week="1" :header-date-format="getMonthFormat" :month-format="getMonthFormat" :weekday-format="getWeekdayFormat" :show-week="true"
                                 :disabled="loading" :loading="loading" :clearable="true" @input="expiresAtSelector = false"
                  />
                </v-dialog>
              </v-col>
              <v-col v-if="false" cols="12" sm="6" md="4">
                <v-text-field v-model="reference" type="text" :counter="referenceMaxLength" dense :rules="referenceRules"
                              :label="$t('purchase.merchandise.items.form.edit.input.reference.label')" :disabled="loading" :loading="loading" :clearable="true"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn color="close" @click.stop="show=false">
              {{ $t('purchase.merchandise.items.form.button.cancel') }}
            </v-btn>
            <v-btn :disabled="!valid || loading" :loading="loading" color="success" @click.stop="validate() && submitMerchandiseItem()">
              {{ $t('purchase.merchandise.items.form.button.save') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </base-material-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import { DateTime } from 'luxon';
  import { MONTH, WEEKDAY_SHORT } from '@/util/DateUtil';
  import { applyTaxRates, removeTaxRates } from '@/util/PriceUtil';
  import { round } from '@/util/MathUtil';

  export default {
    name: 'EditProductForm',
    props: {
      merchandiseItem: {
        type: Object,
        required: true
      },
      value: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        dialog: false,
        loading: false,
        valid: false,
        suppliers: [],
        merchandises: [],
        type: null,
        merchandiseId: undefined,
        merchandiseRules: [
          v => !!v || this.$t('purchase.merchandise.items.form.edit.input.merchandise.rule.required')
        ],
        supplierId: {},
        supplierRules: [
          v => !!v || this.$t('purchase.merchandise.items.form.edit.input.supplier.rule.required')
        ],
        reference: '',
        referenceMinLength: 1,
        referenceMaxLength: 50,
        referenceRules: [
          v => !v || (v && v.length >= this.referenceMinLength) || this.$t('purchase.merchandise.items.form.edit.input.reference.rule.minLength', { count: this.referenceMinLength }),
          v => !v || (v && v.length <= this.referenceMaxLength) || this.$t('purchase.merchandise.items.form.edit.input.reference.rule.maxLength', { count: this.referenceMaxLength })
        ],
        quantity: 1,
        quantityRules: [
          v => !!v || this.$t('purchase.merchandise.items.form.edit.input.quantity.rule.required')
        ],
        unitPriceTaxExcluded: undefined,
        unitPriceTaxExcludedRules: [
          v => !!v || this.$t('purchase.merchandise.items.form.edit.input.unitPriceTaxExcluded.rule.required')
        ],
        unitPriceTaxIncluded: undefined,
        unitPriceTaxIncludedRules: [
          v => !!v || this.$t('purchase.merchandise.items.form.edit.input.unitPriceTaxIncluded.rule.required')
        ],
        packagingCapacity: undefined,
        packagingCapacityMin: 1,
        packagingCapacityMax: 1000,
        packagingCapacityRules: [
          v => !v || (v && v >= this.packagingCapacityMin) || this.$t('purchase.merchandise.items.form.edit.input.packagingCapacity.rule.min', { min: this.packagingCapacityMin }),
          v => !v || (v && v <= this.packagingCapacityMax) || this.$t('purchase.merchandise.items.form.edit.input.packagingCapacity.rule.max', { max: this.packagingCapacityMax }),
          v => !!v || this.$t('purchase.merchandise.items.form.edit.input.packagingCapacity.rule.required')
        ],
        packagingUnit: undefined,
        packagingUnitRules: [
          v => !!v || this.$t('purchase.merchandise.items.form.edit.input.packagingUnit.rule.required')
        ],
        packagingPriceTaxExcluded: undefined,
        packagingPriceTaxExcludedRules: [
          v => !!v || this.$t('purchase.merchandise.items.form.edit.input.packagingPriceTaxExcluded.rule.required')
        ],
        packagingPriceTaxIncluded: undefined,
        packagingPriceTaxIncludedRules: [
          v => !!v || this.$t('purchase.merchandise.items.form.edit.input.packagingPriceTaxIncluded.rule.required')
        ],
        origins: ['AFRIQUE DU SUD', 'ALLEMAGNE', 'ARGENTINE', 'BELGIQUE', 'BRÉSIL', 'CAMEROUN', 'CHILI', 'CHINE', 'COSTA RICA', 'CÔTE D\'IVOIRE', 'ÉGYPTE', 'ÉQUATEUR', 'ESPAGNE', 'ÉTATS-UNIS', 'FRANCE',
                  'KENYA', 'GRÈCE', 'ITALIE', 'MAROC', 'MEXIQUE', 'NOUVELLE-ZÉLANDE', 'PAYS-BAS', 'POLOGNE', 'PORTUGAL', 'SUISSE', 'TAHITI', 'UE', 'NON-UE'],
        origin: undefined,
        originMinLength: 1,
        originMaxLength: 50,
        originRules: [
          v => !v || (v && v.length >= this.originMinLength) || this.$t('purchase.merchandise.items.form.edit.input.origin.rule.minLength', { count: this.originMinLength }),
          v => !v || (v && v.length <= this.originMaxLength) || this.$t('purchase.merchandise.items.form.edit.input.origin.rule.maxLength', { count: this.originMaxLength })
        ],
        category: undefined,
        variety: undefined,
        varietyMinLength: 1,
        varietyMaxLength: 30,
        varietyRules: [
          v => !v || (v && v.length >= this.varietyMinLength) || this.$t('purchase.merchandise.items.form.edit.input.variety.rule.minLength', { count: this.varietyMinLength }),
          v => !v || (v && v.length <= this.varietyMaxLength) || this.$t('purchase.merchandise.items.form.edit.input.variety.rule.maxLength', { count: this.varietyMaxLength })
        ],
        size: undefined,
        sizeMinLength: 1,
        sizeMaxLength: 30,
        sizeRules: [
          v => !v || (v && v.length >= this.sizeMinLength) || this.$t('purchase.merchandise.items.form.edit.input.size.rule.minLength', { count: this.sizeMinLength }),
          v => !v || (v && v.length <= this.sizeMaxLength) || this.$t('purchase.merchandise.items.form.edit.input.size.rule.maxLength', { count: this.sizeMaxLength })
        ],
        expiresAt: undefined,
        expiresAtSelector: false
      };
    },
    computed: {
      show: {
        get () {
          return this.value;
        },
        set (value) {
          this.$emit('input', value);
        }
      },
      isMerchandiseSelected: {
        get () {
          return typeof this.merchandiseId === 'number';
        }
      },
      merchandiseUnit: {
        get () {
          if (!this.isMerchandiseSelected) {
            return '?';
          }

          const unit = this.merchandises.find((m) => m.id === this.merchandiseId)?.unit;
          if (typeof unit !== 'string') {
            return '?';
          }

          return this.$i18n.t('common.unit.' + String(unit) + '.long');
        }
      },
      packagingUnits: {
        get () {
          const data = this.$i18n.t('common.unit');
          const UNITS = Object.keys(data).map((unitKey) => {
            return {
              id: unitKey,
              name: data[unitKey].long
            };
          });

          if (typeof this.merchandiseId === 'number') {
            const merchandise = this.merchandises.find((m) => m.id === this.merchandiseId);
            if (merchandise !== undefined) {
              const filters = [];
              switch (merchandise.unit) {
                case 'count_piece':
                  filters.push('count_piece');
                  break;
                case 'length_metric_meter':
                case 'length_metric_millimeter':
                  filters.push('length_metric_meter');
                  filters.push('length_metric_millimeter');
                  break;
                case 'volume_metric_litre':
                case 'volume_metric_millilitre':
                  filters.push('volume_metric_litre');
                  filters.push('volume_metric_millilitre');
                  break;
                case 'weight_metric_kilogram':
                case 'weight_metric_gram':
                  filters.push('weight_metric_kilogram');
                  filters.push('weight_metric_gram');
                  break;
              }

              return UNITS.filter((UNIT) => filters.includes(UNIT.id));
            }
          }

          return UNITS;
        }
      },
      expiresAtFormatted: {
        get () {
          return this.formatDate(this.expiresAt);
        },
        set () {
          this.expiresAt = undefined;
        }
      }
    },
    watch: {
      merchandiseItem: async function () {
        this.type = this.merchandiseItem.type;
        this.merchandiseId = this.merchandiseItem.merchandise?.id;
        this.supplierId = this.merchandiseItem.supplier?.id;

        this.reference = this.merchandiseItem.reference;
        this.quantity = this.merchandiseItem.quantity;
        this.packagingCapacity = this.merchandiseItem.packagingCapacity;
        this.packagingUnit = this.merchandiseItem.packagingUnit ?? (this.packagingUnits.length === 1 ? this.packagingUnits[0].id : undefined);
        this.packagingPriceTaxExcluded = this.merchandiseItem.packagingPriceTaxExcluded;
        this.packagingPriceTaxIncluded = undefined;
        this.unitPriceTaxExcluded = this.merchandiseItem.unitPriceTaxExcluded;
        this.unitPriceTaxIncluded = undefined;
        this.origin = this.merchandiseItem.origin;
        this.category = this.merchandiseItem.category;
        this.variety = this.merchandiseItem.variety;
        this.size = this.merchandiseItem.size;
        this.expiresAt = this.merchandiseItem.expiresAt;

        if (this.unitPriceTaxExcluded === null) {
          const merchandiseName = this.merchandises.find((m) => m.id === this.merchandiseId)?.name ?? '?';
          const supplierName = this.suppliers.find((m) => m.id === this.supplierId)?.name ?? '?';
          if (confirm(this.$i18n.t('purchase.merchandise.items.check.confirm.cancel', { merchandise: merchandiseName, supplier: supplierName }))) {
            await this.submitMerchandiseItem();
          } else {
            this.show = false;
          }
        } else {
          this.updatePackagingPrices('excluded');
          this.updateUnitPrices('excluded');
        }
      }
    },
    mounted: async function () {
      await this.refreshSuppliers();
      await this.refreshMarchandises();
    },
    methods: {
      formatDate (date) {
        if (!date) {
          return undefined;
        }

        return DateTime.fromISO(date, { setZone: true }).toLocaleString({ locale: 'fr-fr' });
      },
      getMonthFormat (date) {
        const dateTime = DateTime.fromISO(date, { setZone: true });
        return MONTH[dateTime.month] + ' ' + dateTime.year;
      },
      getWeekdayFormat (date) {
        const weekdayId = DateTime.fromISO(date, { setZone: true }).weekday;
        return WEEKDAY_SHORT[weekdayId];
      },
      updateConversionPrice (fromPrice) {
        if (this.packagingCapacity === undefined || this.packagingCapacity === null) {
          return;
        }
        if (this.packagingUnit === undefined || this.packagingUnit === null) {
          return;
        }
        const merchandise = this.merchandises.find((m) => m.id === this.merchandiseId);
        if (merchandise !== undefined) {
          switch (fromPrice) {
            case 'packaging':
              if (this.packagingPriceTaxExcluded !== undefined && this.packagingPriceTaxExcluded !== null && String(this.packagingPriceTaxExcluded).length > 0) {
                if (this.packagingUnit === merchandise.unit) {
                  this.unitPriceTaxExcluded = round(Number(this.packagingPriceTaxExcluded) / Number(this.packagingCapacity), 3);
                } else {
                  this.unitPriceTaxExcluded = round(Number(this.packagingPriceTaxExcluded) * 1000 / Number(this.packagingCapacity), 3);
                }
              }
              break;
            case 'unit':
              if (this.unitPriceTaxExcluded !== undefined && this.unitPriceTaxExcluded !== null && String(this.unitPriceTaxExcluded).length > 0) {
                if (this.packagingUnit === merchandise.unit) {
                  this.packagingPriceTaxExcluded = round(Number(this.unitPriceTaxExcluded) * Number(this.packagingCapacity), 3);
                } else {
                  this.packagingPriceTaxExcluded = round(Number(this.unitPriceTaxExcluded) / 1000 * Number(this.packagingCapacity), 3);
                }
              }
              break;
          }
        }
      },
      updatePackagingPrices (fromPrice) {
        if (fromPrice === 'included' || (fromPrice === undefined && this.packagingPriceTaxIncluded)) {
          this.packagingPriceTaxExcluded = this.packagingPriceTaxIncluded;
          if (typeof this.merchandiseId === 'number') {
            const merchandise = this.merchandises.find((m) => m.id === this.merchandiseId);

            if (merchandise !== undefined) {
              this.packagingPriceTaxExcluded = removeTaxRates(this.packagingPriceTaxIncluded, merchandise.taxRates, 3);
            }
          }
        } else if (fromPrice === 'excluded' || (fromPrice === undefined && this.packagingPriceTaxExcluded)) {
          this.packagingPriceTaxIncluded = this.packagingPriceTaxExcluded;
          if (typeof this.merchandiseId === 'number') {
            const merchandise = this.merchandises.find((m) => m.id === this.merchandiseId);

            if (merchandise !== undefined) {
              this.packagingPriceTaxIncluded = applyTaxRates(this.packagingPriceTaxExcluded, merchandise.taxRates, 2);
            }
          }
        }
      },
      updateUnitPrices (fromPrice) {
        if (fromPrice === 'included' || (fromPrice === undefined && this.unitPriceTaxIncluded)) {
          this.unitPriceTaxExcluded = this.unitPriceTaxIncluded;
          if (typeof this.merchandiseId === 'number') {
            const merchandise = this.merchandises.find((m) => m.id === this.merchandiseId);

            if (merchandise !== undefined) {
              this.unitPriceTaxExcluded = removeTaxRates(this.unitPriceTaxIncluded, merchandise.taxRates, 3);
            }
          }
        } else if (fromPrice === 'excluded' || (fromPrice === undefined && this.unitPriceTaxExcluded)) {
          this.unitPriceTaxIncluded = this.unitPriceTaxExcluded;
          if (typeof this.merchandiseId === 'number') {
            const merchandise = this.merchandises.find((m) => m.id === this.merchandiseId);

            if (merchandise !== undefined) {
              this.unitPriceTaxIncluded = applyTaxRates(this.unitPriceTaxExcluded, merchandise.taxRates, 2);
            }
          }
        }
      },
      updateDefaultPackaging () {
        const merchandise = this.merchandises.find((m) => m.id === this.merchandiseId);
        if (merchandise !== undefined) {
          this.packagingCapacity = 1;
          this.packagingUnit = merchandise.unit;
        }
      },
      updatePackagingCapacity () {
        switch (this.packagingCapacity) {
          case 'length_metric_millimeter':
          case 'volume_metric_millilitre':
          case 'weight_metric_gram':
            this.packagingCapacityMin = 10;
            this.packagingCapacityMax = 999;
            break;
          default:
            this.packagingCapacityMin = 1;
            this.packagingCapacityMax = 1000;
        }
        this.updateConversionPrice('packaging');
      },
      async refreshSuppliers () {
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'merchandise/suppliers?management=manual');
          if (response.data !== undefined && response.data.suppliers !== undefined) {
            this.suppliers = response.data.suppliers;
          }
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('common.error.default'));
        }
      },
      async refreshMarchandises () {
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'merchandises');
          if (response.data !== undefined && response.data.merchandises !== undefined) {
            this.merchandises = response.data.merchandises;
          }
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('common.error.default'));
        }
      },
      async submitMerchandiseItem () {
        this.loading = true;
        try {
          if (this.merchandiseItem?.id === undefined) {
            await this.$http.post(process.env.VUE_APP_BASE_API_URL + 'merchandise/item', {
              merchandiseId: this.merchandiseId,
              reference: this.reference ?? '',
              type: this.type,
              quantity: this.quantity,
              packagingCapacity: this.packagingCapacity,
              packagingUnit: this.packagingUnit,
              packagingPriceTaxExcluded: this.packagingPriceTaxExcluded,
              unitPriceTaxExcluded: this.unitPriceTaxExcluded,
              supplierId: this.supplierId,
              origin: this.origin ?? '',
              category: this.category ?? '',
              variety: this.variety ?? '',
              size: this.size ?? '',
              expiresAt: typeof this.expiresAt === 'string' ? String(this.expiresAt) + 'T12:00:00.000Z' : ''
            });
          } else {
            // TODO
            // await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'merchandise/item', {
            //   id: this.product?.id,
            //   typeId: this.type,
            //   collectionIds: this.collections,
            //   categoryIds: this.categories,
            //   reference: this.reference,
            //   name: this.name,
            //   summary: this.summary === null ? '' : this.summary,
            //   description: this.description === null ? '' : this.description,
            //   taxRates: this.taxRates,
            //   tags: this.tags,
            //   prices: this.prices,
            //   images: this.images,
            //   groupIds: this.groups
            // });
          }
          this.$emit('success');
          this.show = false;
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('common.error.default'));
        }
        this.loading = false;
      },
      validate () {
        return this.$refs.formMerchandiseItem.validate();
      }
    }
  };
</script>
